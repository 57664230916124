import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { Link } from 'react-router-dom';
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import { FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderFour";
const Portfolio_image = <img src="/assets/images/portfolio/web-design.png" alt="Web Design" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/software-dev.png" alt="App Development" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/app-development.png" alt="iOS Development" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/shopify-development.png" alt="Shopify Development" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/cloud-ready.jpg" alt="Cloud Ready" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/printing.png" alt="Custom Printing" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/branding-dev.png" alt="Branding" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/consulting.jpg" alt="Consulting" />;

const list = [
    {
        image: Portfolio_image,
        category: 'Development',
        title: 'Web Design',
        description: 'From Simple Wordpress Sites To Fully Scalable Static Websites.'
    },
    {
        image: Portfolio_image2,
        category: 'Development',
        title: 'Custom Applications',
        description: 'Custom React Web Applications For Internal And External Facing Needs.'
    },
    {
        image: Portfolio_image3,
        category: 'App Development',
        title: 'Native iOS Applications',
        description: 'Native Swift iOS App Development For An Unmatched Client Experience.'
    },
    {
        image: Portfolio_image4,
        category: 'Development',
        title: 'Shopify Development',
        description: 'E-Commerce Solutions For Small To Medium Sized Companies Using Shopify.'
    },
    {
        image: Portfolio_image5,
        category: 'Infrastructure',
        title: 'Cloud Ready',
        description: 'Infinitely Scalable Cloud Solutions Using Amazon Web Services (AWS).'
    },
    {
        image: Portfolio_image6,
        category: 'Design',
        title: 'Custom Printing',
        description: 'Fully Custom Stationary Or Promotional Products Designed In-House.'
    },

    {
        image: Portfolio_image7,
        category: 'Design',
        title: 'Identity And Branding',
        description: 'Branding And Identity That Reflects Your Business Perfectly.'
    },
    {
        image: Portfolio_image8,
        category: 'Services',
        title: 'Consulting',
        description: 'Take It To The Next Level With A Comprehensive Growth Strategy.'
    },
]


const CreativePortfolio = () => {

    return (
        <div className="creative-portfolio">
            <Helmet pageTitle="Attollo Agency" />
            
            {/* Start Header Area  */}
            <Header headerPosition="header--static pt--15 pb--15" logo="symbol-dark" color="color-black"/>
            {/* End Header Area  */}

            {/* Start Portfolio Area  */}
            <div className="creative-portfolio-wrapper bg_color--1 pb--30">
                <div className="creative-portfolio-wrapper plr--30">
                    <div className="row mt_dec--30">
                        {list.map((value , i ) => (
                            <div className="col-xl-3 col-lg-6 col-md-6 col-12 mt--30" key={i}>
                                 <div className="im_portfolio">
                                    <div className="thumbnail_inner">
                                        <div className="thumbnail">
                                            <Link to="#">
                                                {value.image}
                                            </Link>    
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <div className="portfolio_heading">
                                                <div className="category_list">
                                                    <Link to="/portfolio-details">{value.category}</Link>
                                                </div>
                                                <h4 className="title"><Link to="#">{value.title}</Link></h4>
                                            </div>
                                            <div className="portfolio_hover">
                                                <p>{value.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="transparent_link" to="#"></Link>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            {/* End Portfolio Area  */}            

            {/* Start Footer Style  */}
            <Footer />
            {/* End Footer Style  */}
            
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default CreativePortfolio;
